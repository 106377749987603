import React from 'react';
import Helmet from 'react-helmet';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import cx from 'classnames';
import ShareButtons from 'components/common/Share';
import ScrollCta from 'components/common/ScrollCta';
import { Link, graphql } from 'gatsby';
import BlogLayout from 'components/common/blog/BlogLayout';
import StructuredArticle from 'components/common/meta/StructuredArticle';
import StructuredFAQ from 'components/common/meta/StructuredFAQ';
import StructuredData from 'components/common/meta/StructuredData';
import Container from 'react-bootstrap/Container';
import CategoryTag from 'templates/Blog/common/CategoryTag';
import PostListCarousel from 'templates/Blog/common/PostList/Carousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Blocks from 'templates/Blog/Gutenberg/Blocks';
import Summary from './Summary';
import Breadcrumb, { postBreadcrumbs } from 'templates/Blog/common/Breadcrumb';
import { Author } from 'templates/Blog/common/Author';
import { stripHtml } from 'templates/Blog/utils';
import { GatsbyImage } from 'gatsby-plugin-image';
import StructuredBlogPage from './StructuredBlogPage';
import { findMedicalAdvisorFor } from 'components/Author/data';
import Segment from 'utils/segment'

import './styles.sass';

class Post extends React.Component {

  render() {
    const {
      data: {
        post, previous, next, featuredPosts,
      },
    } = this.props;

    let filteredBlocks = post.blocks;
    const firstHeadingIndex = filteredBlocks.findIndex(
      (it) => it.name == 'core/heading' && it.attributes.level == 2,
    );

    let introductionParagraphBlocks = null;

    if (firstHeadingIndex != -1) {
      introductionParagraphBlocks = filteredBlocks
        .slice(0, firstHeadingIndex)
        .filter((it) => it.name == 'core/paragraph');
      filteredBlocks = filteredBlocks.filter(
        (it) => !introductionParagraphBlocks.includes(it),
      );
    }

    const questionsAndAnswers = filterQAsFromBlocks(filteredBlocks);
    const doctor = findMedicalAdvisorFor(post)
    const tags = post.tags.nodes.filter(tag => !tag.slug.startsWith("no-page"));

    return (
      <BlogLayout>
        <CharlesMeta
          title={post.seo.title}
          description={post.seo.metaDesc}
          image={
            post.socialImage.node.localFile.childImageSharp.gatsbyImageData
              .images.fallback.src
          }
          segmentCategory={Segment.CATEGORIES.BLOG_POST}
        />
        <StructuredBlogPage slug={post.slug} />
        <Helmet defer={false}>
          <meta name="charles:external:id" content={post.id} />
          <meta name="charles:external:dbid" content={post.databaseId} />
        </Helmet>
        <Container className="blog-post-container">
          <Breadcrumb links={postBreadcrumbs(post)} />
          <StructuredArticle postId={post.id} />
          <StructuredFAQ data={questionsAndAnswers} />
          {post.fields.structuredDatas.map(data => (
            <StructuredData data={data} />
          ))}
          <h1>{post.title}</h1>
          <Row className="blog-post-meta">
            <Col xs={6} lg={4}>
              <Author data={post.author.node} />
            </Col>
            <Col xs={6} lg={4}>
              <Author data={doctor} />
            </Col>
            <Col xs={12} lg={4}>
              <div className="blog-post-date">
                Mis à jour le
                {' '}
                <br className="d-none d-md-block" />
                {post.modified}
              </div>
            </Col>
          </Row>
          <div className="position-relative blog-post-image">
            <GatsbyImage
              image={
                post.featuredImage.node.localFile.childImageSharp
                  .gatsbyImageData
              }
              className="img-fluid"
              alt={post.title}
            />
          </div>
          <Blocks context={post} blocks={introductionParagraphBlocks} />
          <Summary post={post} />
          <Blocks context={post} blocks={filteredBlocks} />
          <div className={cx({"share-links-blog" : featuredPosts.nodes.length < 1})}>
            <ShareButtons title={post.title} uri={post.uri} />
          </div>

          {featuredPosts.nodes.length >= 1
            ? (
              <div className="featured-articles-div">
                <PostListCarousel
                  title="À voir aussi"
                  posts={featuredPosts.nodes}
                  className="mt-4 mt-md-0 mb-3"
                />
                <ScrollCta />
              </div>
            )
            : null}

          {tags.length > 0 && <CategoryTag  tags={tags} /> }
        </Container>
      </BlogLayout>
    );
  }
}

export default Post;

function filterQAsFromBlocks(blocks) {
  const qas = [];

  let tmp = { question: null, answer: null };
  let isAnswering = false;
  let questionLevel;

  for (const block of blocks) {
    if (block.name == 'core/heading') {
      if (isAnswering && block.attributes.level <= questionLevel) {
        qas.push(tmp);
        isAnswering = false;
        tmp = { question: null, answer: null };
      }
      if (block.originalContent.match('g-faq')) {
        isAnswering = true;
        questionLevel = block.attributes.level;
        tmp.question = stripHtml(block.originalContent);
      }
    } else if (
      isAnswering
      && (block.name == 'core/paragraph' || block.name == 'core/heading')
    ) {
      if (tmp.answer) tmp.answer += ' ';
      else tmp.answer = '';
      tmp.answer += stripHtml(block.originalContent);
    }
  }

  if (isAnswering == true) qas.push(tmp);

  return qas;
}

export const socialImageFragment = graphql`
  fragment SocialImage on File {
    childImageSharp {
      gatsbyImageData(width: 800, height: 420)
    }
  }
`;

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $categoryId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      databaseId
      modified(formatString: "DD MMMM YYYY", locale: "FR")
      excerpt
      content
      title
      slug
      uri
      fields {
        structuredDatas
      }
      categories {
        nodes {
          id
          slug
          name
        }
      }
      date(formatString: "DD MMMM YYYY", locale: "FR")
      author {
        node {
          name
          firstName
          lastName
          uri
          slug
        }
      }
      featuredImage {
        node {
          ...BlogImage
        }
      }
      socialImage: featuredImage {
        node {
          localFile {
            ...SocialImage
          }
        }
      }
      blocks {
        ...GutenbergBlock
      }
      seo {
        metaDesc
        title
      }
      tags {
        nodes {
          id
          name
          slug
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
    featuredPosts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
        isSticky: { eq: true }
        id: { ne: $id }
      }
    ) {
      nodes {
        ...BlogPostThumbnail
      }
    }
  }
`;
